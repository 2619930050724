  <template>
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>Primary Information</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <form @submit.prevent="handleunits" role="form">
          <div class="form-row">
            <div class="form-col" v-for="field in formFields" :key="field.id">
              <label>{{ field.name }}</label>
              <soft-input
                v-model="formValues[field.vmodel]"
                :name="field.name"
                :id="field.id"
                :type="field.type"
                :placeholder="field.placeholder"
                :aria-label="field.ariaLabel"
                :isRequired="field.required"
              />
            </div>
          </div>
          <div class="text-center">
            <soft-button
              color="dark"
              full-width
              variant="gradient"
              class="my-4 mb-2"
              type="submit"
            >
              Submit
            </soft-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import SoftInput from "@/components/SoftInput.vue";
  import { mapMutations, mapActions } from "vuex";
  import formFields from "@/forms_fields/units_primary.json";
  import SoftButton from "@/components/SoftButton.vue";
  
  export default {
    name: "UnitsPrimary",
    components: {
      SoftInput,
      SoftButton,
    },
    data() {
      return {
        formFields: formFields,
        formValues: {
          name: '',
          description: ''
        },
        error: null,
      };
    },
    methods: {
      ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
      ...mapActions(['registerUnit']),
      async handleunits() {
        try {
          const userData = { ...this.formValues };
          console.log('here is userdata');
          console.log(userData);
  
          const token = localStorage.getItem('token');
  
          // Set up the config for the request with the Authorization header
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          console.log('here is config');
          console.log(config);
          // Pass userData and config to your registerUnit action
          await this.registerUnit({ userData, config });
  
          // Redirect to the home page after successful registration
          this.$router.push({ name: '/inventory' });
        } catch (err) {
          if (err.response && err.response.data) {
            this.error = err.response.data.detail; // Display specific error message from API response
          } else {
            this.error = 'Something went wrong. Please try again.'; // Fallback generic error message
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .form-col {
    flex: 1 1 calc(50% - 10px);
  }
  </style>
  