import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import axios from 'axios';

const app = createApp(App);
app.use(store);
app.use(SoftUIDashboard);
app.use(router);
const token = localStorage.getItem('token');
console.log('token = ',token)
console.log('local ',localStorage);

// if (token) {
//     // Optionally, validate the token with the server
//     axios.get('/api/protected/', { headers: { Authorization: `Bearer ${token}` } })
//       .then(response => {
//         if (response.data.valid) {
//           store.commit('setToken', token);
//           router.push('/dashboard');
//         } else {
//           localStorage.removeItem('token');
//         }
//       })
//       .catch(() => {
//         localStorage.removeItem('token');
//       });
//   }
app.mount('#app'); // This is crucial to mount your app
