<template>
    <div class="card mb-4">
      <div class="card-header pb-0">
        <h6>Primary Information</h6>
      </div>
        <div class="card-body px-0 pt-0 pb-2">
            <form @submit.prevent='handleregister' role="form">
              <div class="form-row">
              <div class="form-col" v-for="field in formFields" :key="field.id">
                <label>{{field.name}}</label>
                <soft-input
                  v-model="formValues[field.vmodel]"
                  :name="field.name"
                  :id="field.id"
                  :type="field.type"
                  :placeholder="field.placeholder"
                  :aria-label="field.ariaLabel"
                  :isRequired = "field.required"
                />
              </div>
            </div>
              <!-- <soft-checkbox
                id="flexCheckDefault"
                name="flexCheckDefault"
                class="font-weight-light"
                checked
              >
                I agree the
                <a href="javascript:;" class="text-dark font-weight-bolder">
                  Terms and Conditions
                </a>
              </soft-checkbox> -->

              <!-- <div class="text-center">
                <soft-button
                  color="dark"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                >
                  Sign up
                </soft-button>
              </div> -->
            </form>
          </div>
      </div>

   

  </template>
  
  <style scoped>
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .form-col {
    flex: 1 1 calc(50% - 10px);
  }
  </style>
  



  <script>
//   import SoftAvatar from "@/components/SoftAvatar.vue";
//   import SoftBadge from "@/components/SoftBadge.vue";
  import SoftInput from "@/components/SoftInput.vue";
  // import SoftCheckbox from "@/components/SoftCheckbox.vue";
  // import SoftButton from "@/components/SoftButton.vue";
  import formFields from "@/forms_fields/sales_order_primary.json"
  export default {
    name: "SalesPrimary",
    // data() {
    //   return {
    //   };
    // },
    components: {
      SoftInput,
      // SoftCheckbox,
      // SoftButton
    },
  data() {
    return {
      formFields: formFields,
      formValues: {
        salesrep: '',
        customer: '',
        date: '',
        purchaseorder: '',
        parentcompany: '',
        status: '',
        shipaddress: '',
        paymentterms: ''
      },
      error: null,
    };
  },
  };
  </script>
  