<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <SalesPrimary />
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12">
          <projects-table />
        </div>
      </div> -->
    </div>
  </template>
<script>
import { mapGetters } from 'vuex';
import SalesPrimary from "./components/SalesPrimary"
    export default {
        name :"Reports",
        components: {
          SalesPrimary,
        },
        computed: {
            ...mapGetters(['isLoggedIn']),
        },
        created() {
            if (this.isLoggedIn) {
            // this.$store.dispatch('Dashboard')
            this.$router.push('/reports');
            } else {
            this.$router.push('/');
            }
        },
    }
</script>