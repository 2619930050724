<template>
  <navbar btn-background="bg-gradient-primary" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Welcome!</h1>
          <p class="text-white text-lead">
            Use these awesome forms to login or create new account in your
            project for free.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Register</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent='handleregister' role="form">
              <div class="mb-3" v-for="field in formFields" :key="field.id">
                <soft-input
                  v-model="formValues[field.vmodel]"
                  :name="field.name"
                  :id="field.id"
                  :type="field.type"
                  :placeholder="field.placeholder"
                  :aria-label="field.ariaLabel"
                />
              </div>
              <soft-checkbox
                id="flexCheckDefault"
                name="flexCheckDefault"
                class="font-weight-light"
                checked
              >
                I agree the
                <a href="javascript:;" class="text-dark font-weight-bolder">
                  Terms and Conditions
                </a>
              </soft-checkbox>

              <div class="text-center">
                <soft-button
                  color="dark"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                >
                  Sign up
                </soft-button>
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <router-link
                  :to="{ name: 'Sign In' }"
                  class="text-dark font-weight-bolder"
                >
                  Sign in
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapMutations, mapActions } from "vuex";
import formFields from "@/forms_fields/signup.json";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    SoftCheckbox,
    SoftButton,
  },
  data() {
    return {
      formFields: formFields,
      formValues: {
        username: '',
        name: '',
        designation: '',
        email: '',
        password: '',
        password2: '',
      },
      error: null,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['registerUser']),
    async handleregister() {
      try {
        const userData = { ...this.formValues };
        console.log(userData);
        await this.registerUser(userData);
        this.$router.push({ name: '/' }); // Redirect to login page after successful registration
      } catch (err) {
        if (err.response && err.response.data) {
          this.error = err.response.data.detail; // Display specific error message from API response
        } else {
          this.error = 'Something went wrong. Please try again.'; // Fallback generic error message
        }
      }
    },
  },
};
</script>
