import { createStore } from 'vuex';
import axios from 'axios';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

const apiClient = axios.create({
  baseURL: 'http://api.techiacs.com', // Adjust this to your Django API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: '',
    isRTL: false,
    color: '',
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed: 'position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4',
    absolute: 'position-absolute px-4 mx-0 w-100 z-index-2',
    bootstrap,
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    status: '',
    user: {},
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      state.isPinned = !state.isPinned;
    },
    setSidebarType(state, payload) {
      state.isTransparent = payload;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setCardBackground(state, payload) {
      state.color = payload;
    },
    setNavbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, { token, refreshToken }) {
      state.status = 'success';
      state.token = token;
      state.refreshToken = refreshToken;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
      state.refreshToken = '';
      state.user = {};
    },
    set_user(state, user) {
      state.user = user;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit('setSidebarType', payload);
    },
    setCardBackground({ commit }, payload) {
      commit('setCardBackground', payload);
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        apiClient.post('/token/', user)
          .then(resp => {
            const token = resp.data.access;
            const refreshToken = resp.data.refresh;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            commit('auth_success', { token, refreshToken });
            commit('set_user', user.username);
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        delete apiClient.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    refreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiClient.post('/token/refresh/', { refresh: state.refreshToken })
          .then(resp => {
            const token = resp.data.access;
            localStorage.setItem('token', token);
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            commit('auth_success', { token, refreshToken: state.refreshToken });
            resolve(resp);
          })
          .catch(err => {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            reject(err);
          });
      });
    },
    // fetchUser({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     apiClient.get('/user/profile/')
    //       .then(resp => {
    //         commit('set_user', resp.data);
    //         resolve(resp);
    //       })
    //       .catch(err => {
    //         commit('logout');
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('refreshToken');
    //         reject(err);
    //       });
    //   });
    // },
    registerUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        apiClient.post('/register/', user)
          .then(resp => {
            commit('auth_request');
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            reject(err);
          });
      });
    },
    registerUnit({ commit }, {userData, config}) {
      return new Promise((resolve, reject) => {
        apiClient.post('/management/units/', userData, config)
          .then(resp => {
            commit('auth_request');
            resolve(resp);
          })
          .catch(err => {
            commit('auth_error');
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
  },
});



// recent previous
// import { createStore } from 'vuex';
// import axios from 'axios';
// import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

// const apiClient = axios.create({
//   baseURL: 'http://127.0.0.1:8000/api', // Adjust this to your Django API URL
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// export default createStore({
//   state: {
//     hideConfigButton: false,
//     isPinned: true,
//     showConfig: false,
//     isTransparent: '',
//     isRTL: false,
//     color: '',
//     isNavFixed: false,
//     isAbsolute: false,
//     showNavs: true,
//     showSidenav: true,
//     showNavbar: true,
//     showFooter: true,
//     showMain: true,
//     navbarFixed: 'position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4',
//     absolute: 'position-absolute px-4 mx-0 w-100 z-index-2',
//     bootstrap,
//     token: localStorage.getItem('token') || '',
//     refreshToken: localStorage.getItem('refreshToken') || '',
//     status: '',
//     user: {},
//   },
//   mutations: {
//     toggleConfigurator(state) {
//       state.showConfig = !state.showConfig;
//     },
//     navbarMinimize(state) {
//       state.isPinned = !state.isPinned;
//     },
//     setSidebarType(state, payload) {
//       state.isTransparent = payload;
//     },
//     toggleHideConfig(state) {
//       state.hideConfigButton = !state.hideConfigButton;
//     },
//     setCardBackground(state, payload) {
//       state.color = payload;
//     },
//     setNavbarFixed(state) {
//       state.isNavFixed = !state.isNavFixed;
//     },
//     toggleEveryDisplay(state) {
//       state.showNavbar = !state.showNavbar;
//       state.showSidenav = !state.showSidenav;
//       state.showFooter = !state.showFooter;
//     },
//     auth_request(state) {
//       state.status = 'loading';
//     },
//     auth_success(state, { token, refreshToken }) {
//       state.status = 'success';
//       state.token = token;
//       state.refreshToken = refreshToken;
//     },
//     auth_error(state) {
//       state.status = 'error';
//     },
//     logout(state) {
//       state.status = '';
//       state.token = '';
//       state.refreshToken = '';
//       state.user = {};
//     },
//     set_user(state, user) {
//       state.user = user;
//     },
//   },
//   actions: {
//     toggleSidebarColor({ commit }, payload) {
//       commit('setSidebarType', payload);
//     },
//     setCardBackground({ commit }, payload) {
//       commit('setCardBackground', payload);
//     },
//     login({ commit }, user) {
//       return new Promise((resolve, reject) => {
//         commit('auth_request');
//         apiClient.post('/token/', user)
//           .then(resp => {
//             const token = resp.data.access;
//             const refreshToken = resp.data.refresh;
//             localStorage.setItem('token', token);
//             localStorage.setItem('refreshToken', refreshToken);
//             apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//             commit('auth_success', { token, refreshToken });
//             console.log('local storage = ',localStorage);
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('auth_error');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     logout({ commit }) {
//       return new Promise((resolve) => {
//         commit('logout');
//         localStorage.removeItem('token');
//         localStorage.removeItem('refreshToken');
//         delete apiClient.defaults.headers.common['Authorization'];
//         resolve();
//       });
//     },
//     refreshToken({ commit, state }) {
//       return new Promise((resolve, reject) => {
//         apiClient.post('/token/refresh/', { refresh: state.refreshToken })
//           .then(resp => {
//             const token = resp.data.access;
//             localStorage.setItem('token', token);
//             apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//             commit('auth_success', { token, refreshToken: state.refreshToken });
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('logout');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     fetchUser({ commit }) {
//       return new Promise((resolve, reject) => {
//         apiClient.get('/user/profile/')
//           .then(resp => {
//             commit('set_user', resp.data);
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('logout');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     registerUser({ commit }, user) {
//       return new Promise((resolve, reject) => {
//         apiClient.post('/register/', user)
//           .then(resp => {
//             commit('auth_request');
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('auth_error');
//             reject(err);
//           });
//       });
//     },
//   },
//   getters: {
//     isLoggedIn: state => !!state.token,
//     authStatus: state => state.status,
//     user: state => state.user,
//   },
// });

//old

// import { createStore } from 'vuex';
// import axios from 'axios';
// import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

// const apiClient = axios.create({
//   baseURL: 'http://127.0.0.1:8000/api', // Django API URL
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// export default createStore({
//   state: {
//     hideConfigButton: false,
//     isPinned: true,
//     showConfig: false,
//     isTransparent: "",
//     isRTL: false,
//     color: "",
//     isNavFixed: false,
//     isAbsolute: false,
//     showNavs: true,
//     showSidenav: true,
//     showNavbar: true,
//     showFooter: true,
//     showMain: true,
//     navbarFixed:
//       "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
//     absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
//     bootstrap,
//     token: localStorage.getItem('token') || '',
//     refreshToken: localStorage.getItem('refreshToken') || '',
//     status: '',
//     user: {},
//   },
//   mutations: {
//     toggleConfigurator(state) {
//       state.showConfig = !state.showConfig;
//     },
//     navbarMinimize(state) {
//       const sidenav_show = document.querySelector(".g-sidenav-show");
//       if (sidenav_show.classList.contains("g-sidenav-hidden")) {
//         sidenav_show.classList.remove("g-sidenav-hidden");
//         sidenav_show.classList.add("g-sidenav-pinned");
//         state.isPinned = true;
//       } else {
//         sidenav_show.classList.add("g-sidenav-hidden");
//         sidenav_show.classList.remove("g-sidenav-pinned");
//         state.isPinned = false;
//       }
//     },
//     sidebarType(state, payload) {
//       state.isTransparent = payload;
//     },
//     cardBackground(state, payload) {
//       state.color = payload;
//     },
//     navbarFixed(state) {
//       state.isNavFixed = !state.isNavFixed;
//     },
//     toggleEveryDisplay(state) {
//       state.showNavbar = !state.showNavbar;
//       state.showSidenav = !state.showSidenav;
//       state.showFooter = !state.showFooter;
//     },
//     toggleHideConfig(state) {
//       state.hideConfigButton = !state.hideConfigButton;
//     },
//     auth_request(state) {
//       state.status = 'loading';
//     },
//     auth_success(state, { token, refreshToken }) {
//       state.status = 'success';
//       state.token = token;
//       state.refreshToken = refreshToken;
//     },
//     auth_error(state) {
//       state.status = 'error';
//     },
//     logout(state) {
//       state.status = '';
//       state.token = '';
//       state.refreshToken = '';
//       state.user = {};
//     },
//     set_user(state, user) {
//       state.user = user;
//     },
//   },
//   actions: {
//     toggleSidebarColor({ commit }, payload) {
//       commit('sidebarType', payload);
//     },
//     setCardBackground({ commit }, payload) {
//       commit('cardBackground', payload);
//     },
//     login({ commit }, user) {
//       return new Promise((resolve, reject) => {
//         commit('auth_request');
//         console.log('request');
//         console.log(user);
//         apiClient.post('/token/', user)
//           .then(resp => {
//             console.log('response');
//             console.log(resp.data);
//             const token = resp.data.access;
//             console.log('token');
//             console.log(resp.data.refresh);
//             console.log('access');
//             console.log(resp.data.access);
//             const refreshToken = resp.data.refresh;
//             commit('auth_success', {token,refreshToken});
//             localStorage.setItem('token', token);
//             localStorage.setItem('refreshToken', refreshToken);
//             apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//             commit('auth_success', { token, refreshToken });
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('auth_error');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     logout({ commit }) {
//       return new Promise((resolve) => {
//         commit('logout');
//         localStorage.removeItem('token');
//         localStorage.removeItem('refreshToken');
//         delete apiClient.defaults.headers.common['Authorization'];
//         resolve();
//       });
//     },
//     refreshToken({ commit, state }) {
//       return new Promise((resolve, reject) => {
//         apiClient.post('/token/refresh/', { refresh: state.refreshToken })
//           .then(resp => {
//             const token = resp.data.access;
//             localStorage.setItem('token', token);
//             apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//             commit('auth_success', { token, refreshToken: state.refreshToken });
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('logout');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     fetchUser({ commit}) {
//       return new Promise((resolve, reject) => {
//         apiClient.get('/user/profile/')
//           .then(resp => {
//             commit('set_user', resp.data);
//             resolve(resp);
//           })
//           .catch(err => {
//             commit('logout');
//             localStorage.removeItem('token');
//             localStorage.removeItem('refreshToken');
//             reject(err);
//           });
//       });
//     },
//     registerUser({commit}, user){
//       return new Promise((resolve, reject) => {
//         apiClient.post('/register/', user).then(resp => {
//           commit('auth_request');
//           resolve(resp);
//         }).catch(err => {
//           commit('auth_error');
//           reject(err);
//         });
//       });
//     }
//   },
//   getters: {
//     isLoggedIn: state => !!state.token,
//     authStatus: state => state.status,
//     user: state => state.user,
//   },
// });





